.sideBox {
    height: 341px;
    width: 1.6rem;
    /* background-color: #fff; */
    background-color: white;
    position: absolute;
    top: 5.2rem;

}
.left{
    left: 6.5rem;
}

.right{
    right: 0.3rem;
}

.topBox
{
    height: 1rem;
    width: calc(100% - 9.8rem);
    background-color: white;
    position: absolute;
    left: 8rem;
    right: 0rem;
}

.top{
    top: 5.2rem;
    /* z-index: -1; */
}

.bottom{
    top:383px;
}

.topBox_packMetrics
{
    height: 3.5rem;
    width: 11.5rem;
    background-color: #fff;
    position: absolute;
    left: 1.1rem;
    right: 0rem;
    top: 18.2rem;
}