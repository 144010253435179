/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"); */


.tableComp{
    /* display: flex; */
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 80%;
    font-family: "Roboto";
    /* background-color: #212138; */
    margin: 10%;
}
.dropDown{
    display: flex;
    flex-direction: column;
    width: 250px;
    /* z-index: 100; */
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
  

}
.dropDownTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.dropDownTop p{
    font-size: 15px;
    font-weight: 500;
}
.dropDownTop button{
    justify-content: center;
    align-items: center;
}
.itemDiv{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    height: 1.5rem;
    padding: 0.5rem 0rem;
}
.dropDownMiddle
{
    margin-top: 0.5rem;

}
.dropDownMiddle li {
    padding: 0px!important;
}
.itemDiv p{
    margin-left: 0.8rem;
}
.dropDownBottom{

    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    
    }
    
    .dropDownBottom button{
        margin: 0px 0px ;
        padding: 4px 10px;
        font-size: 12px;
    }
    

.disabled-row {
    background-color: #dcdcdc;
    /* background-color: red; */
    pointer-events: none;
  }

.copyIcon{
   
    cursor: pointer;
    margin:  0 15px;
}


.dropDownBox{
    width: 500px;
    display: flex;
    justify-content: flex-end;
}
.dropDowndDiv{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}





.checkbox-color .ant-checkbox-disabled .ant-checkbox-inner{
  background-color: #d9d9d9;
  border-color:#d9d9d9;
  display: none;
}