.date_picker_div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: grey; */

}

.date_picker {
    width: 1000px;
}


.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {

    display: none;

}


.rIcon {
    position: relative;
    left: -23px;
    z-index: 10;
    height: 1.3rem;
}