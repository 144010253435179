@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');

* {
    font-family: 'Helvetica';
}

.tooltip_container {
    padding: 15px;
}

.time_container {
    font-size: 12px;
    font-weight: 400;
    color: #22105C;
}

.flex_center
{
    display: flex;
    align-items: center;
}

.dot_purple {
    height: 13px;
    width: 13px;
    background-color: #4f44e0;
    border-radius: 50%;
    display: inline-block;
}
.dot_red {
    height: 13px;
    width: 13px;
    background-color: #FF7557;
    border-radius: 50%;
    display: inline-block;
}
.dot_yellow {
    height: 13px;
    width: 13px;
    background-color: #F8BC3B;
    border-radius: 50%;
    display: inline-block;
}
.dot_green {
    height: 13px;
    width: 13px;
    background-color: #229087;
    border-radius: 50%;
    display: inline-block;
}
.dot_minCell {
    height: 13px;
    width: 13px;
    background-color: #DD731C;
    border-radius: 50%;
    display: inline-block;
}.dot_cellDiff {
    height: 13px;
    width: 13px;
    background-color: #0876CC;
    border-radius: 50%;
    display: inline-block;
}.dot_capacity {
    height: 13px;
    width: 13px;
    background-color: #6C3483;
    border-radius: 50%;
    display: inline-block;
}

.dot-bmsError-0 {
    height: 13px;
    width: 13px;
    background-color: #D64174;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-1 {
    height: 13px;
    width: 13px;
    background-color: #db5986;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-2 {
    height: 13px;
    width: 13px;
    background-color: #df6a92;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-3 {
    height: 13px;
    width: 13px;
    background-color: #e68dac;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-4 {
    height: 13px;
    width: 13px;
    background-color: #ea9fb9;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-5 {
    height: 13px;
    width: 13px;
    background-color: #eeb0c5;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-6 {
    height: 13px;
    width: 13px;
    background-color: #f2c2d2;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-7 {
    height: 13px;
    width: 13px;
    background-color: #f4cad8;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-8 {
    height: 13px;
    width: 13px;
    background-color: #f9e5ec;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-9 {
    height: 13px;
    width: 13px;
    background-color: #fae9ef;
    border-radius: 50%;
    display: inline-block;
}

.text_container
{
    /* margin-left: 10px; */
    font-size: 12px;
    font-weight: 400;
    color: #22105C;
}

.battery_status_container
{
    font-size: 12px;
    font-weight: 400;
    color: #22105C;
}

.horizontal_rule
{
    border-top: 1px solid #ececec;
}
.horizontal_rule_white
{
    border-top: 0px none #fff;
}

.displaying_None
{
    display: none;
}
.displaying_Br_None
{
    display: none;
}
.displaying_Br
{
    display: inline-block;
}
.margin_Top
{
    padding: 10px;
}