@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap);
/* @import url('https://fonts.cdnfonts.com/css/helvetica-neue-9'); */

/* * {
  scroll-behavior: smooth;
} */

html {
  font-size: 10px;
}

body {
  margin: 0px;
}

.inactiveClass {
  color: grey;
  display: flex;
  text-decoration: none;
  background-color: none;
  margin: 20px 10px;
}

.activeClass {
  color: black;
  display: flex;
  text-decoration: none;
  background-color: #4f44e0;
  margin: 20px 10px;
  font-size: 2rem;
  border-radius: 15px;
}

.icons_enlarge {
  -webkit-transform: scale(1);
          transform: scale(1);

  transition-duration: 0.5s;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding: 0px;
}

.gm-ui-hover-effect {
  display: none !important;
}

.icons_enlarge:hover {
  -webkit-transform: scale(1.120);
          transform: scale(1.120);
  transition-duration: 0.5s;
  /* background-color: blue; */
}

.highlight {
  border: 1.5px solid #e8e8e8;
  border-radius: 12px
}

.highlight:hover {
  border: 1.5px solid #4f44e0;
  /* border-radius: 12px */
}

.sectionHeader {
  padding: 8px 12px,
}

.section {
  padding: 12px
}

.heading {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  color: #22105C,
}

.MuiTableCell-root {
  padding: 2px !important;
}

.MuiToolbar-root {
  min-height: 50px !important;
  padding: 0px 0px 0px 0px !important;
}

.mytable {
  position: relative;
  height: 100%;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}

.multiple_Auto.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding: 2px 0px 2px 5px !important;
}

.multiple_Auto_Checkbox.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding: 2px 0px 2px 5px !important;
}

#vaidehi .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall
{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 0px !important;
}
input#vaidehi.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused
{
  margin-left: 0px !important;
}

.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root
{
  padding-right: 0px !important;
}

.stickyDiv
{
  position: -webkit-sticky;
        position: sticky;
        top: 57px;
        background-color: #fff;
        padding: 5px 0px;
        font-size: 25px;
        z-index: 999;

}

/* ul.MuiAutocomplete-listbox
{
  background-color: #22105C;
} */
/* .MuiAutocomplete-popper.MuiPopperUnstyled-root
{
  background-color: pink !important;
} */

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root{
  background-color: rgb(28,28,28) !important;
  top: -40px !important;
  right: 0px !important;
  left: -10px !important;
  border-radius: 4px !important;
  height: -webkit-max-content !important;
  height: max-content !important;
}
.MuiSnackbarContent-message
{
  padding: 0px !important;
}
.apexcharts-point-annotation-marker:hover {
  cursor: pointer;
}

.apexcharts-pie-area 
{
  cursor: pointer;
}

.apexcharts-marker
{
  cursor: pointer;
}

.mytable .cell {

  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}

#overflow-text {

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;

}

h1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

h1:hover {
  overflow: visible;
}

#overflow-text-1 {

  width: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

#overflow-text-2 {

  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

#overflow-text-3 {

  width: 66.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

#overflow-text-4 {

  width: 70.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.apexcharts-tooltip {
  background-color: #fff;
}

.apexcharts-toolbar
{
  background-color: #fff;
  padding: 0px !important;
}

.bizcharts-tooltip.g2-tooltip {
  border-radius: 5px !important;
  border: 1px solid #e3e3e3 !important;

  box-shadow: 2px 2px 6px -4px #999 !important;
  padding: 0px !important;
  /* cursor: default;
    font-size: 14px; */
  /* left: 62px;
    opacity: 0; */
  /* pointer-events: none;
    position: absolute; */
  /* top: 20px; */
  /* display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    z-index: 12; */
  /* transition: 0.15s ease all; */
}

.bizcharts-tooltip-title {
  background: #ECEFF1;
  border-bottom: 1px solid #ddd;
  padding: 6px;
  margin-bottom: 4px;
}

.bizcharts-tooltip-series-group {
  padding: 5px 10px;
  /* display: none; */
  text-align: left;
  justify-content: left;
  align-items: center;
  padding-bottom: 4px;
}

.apexcharts-menu-item.exportCSV {
  display: none!important;
}

.middle-div {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.apexcharts-selection-rect+g .svg_select_points_l,
.apexcharts-selection-rect+g .svg_select_points_r {
  cursor: ew-resize;
  opacity: 1;
  visibility: hidden !important;
}

.apexcharts-legend {
  justify-content: center !important;
}

.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg {
  fill: #6D72F6 !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #6D72F6 !important;
}

#lastLabel .apexcharts-xaxis-label:nth-last-child(1) {
  /* transform: translateX(-70px) */
  display: none;
}

.apexcharts-legend-series {
  display: flex;
}

#pie-legends-largeWidth .apexcharts-legend-text {
  max-width: 160px;
}

#hideAlternateLabel .apexcharts-xaxis-label:nth-last-child(2n) {
  display: none;
  /* &:nth-child(5n){ display:revert; } */
}

#googleTimeline text {
  fill: #666666;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}

.MuiAutocomplete-endAdornment {
  top: 8px !important;
}

.MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input {
  color: #4f44e0;
  font-weight: 600;
}

.MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  font-size: "12px !important";
}

.ant-notification
{
  z-index: 1500 !important
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
  box-shadow: none !important;
  top: 259px;
  right: 425px;
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  background-color: #fff;
  height: 22px;
  min-width: -webkit-max-content;
  min-width: max-content;
  border-radius: 12px;
}

.MuiSnackbarContent-action {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5px !important;
}

.slick-prev:before {
  color: #4f44e0 !important;
}

.slick-next:before {
  color: #4f44e0 !important;
}

.slick-track>* {
  /* background-color: #6D72F6; */
  display: flex !important;
  justify-content: center !important;
}


.BatteryPack>*>*>*>*
{
  display: unset !important;
  justify-content: unset !important;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc.css-12lfbf6-MuiSvgIcon-root-MuiTableSortLabel-icon
{
  opacity: 1 !important;
} */

/* table */

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: rgb(251, 251, 250);
}

li[id^=vaidehi-option].MuiAutocomplete-option
{
  /* background-color: pink !important; */
  display: flex !important;
  justify-content: center !important;
}


.LandingPageTable .ant-table-body {
  min-height: 500px;
}

.LandingPageTable.Selection .ant-table-thead>tr>th:nth-child(2) {
  pointer-events: none !important;
}

.LandingPageTable.NoSelection .ant-table-thead>tr>th:nth-child(1) {
  pointer-events: none !important;
}

/* .ant-table-thead > tr > th:nth-child(2) {
  pointer-events: none !important;
} */

.LandingPageTable_FleetBatteryHealth .ant-table-body {
  min-height: 700px;
}

.LandingPageTable_FleetBatteryHealth .ant-table-thead>tr>th:nth-child(1) {
  pointer-events: none !important;
}

.SpeificBatteryPageTable .ant-table-body {
  min-height: 300px;
}

.CartTable .ant-table-body {
  min-height: 430px;
}
.CartTable .ant-table-thead>tr>th:nth-child(2) {
  pointer-events: none !important;
}
.CartTable .ant-table-row
{
  cursor: default !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #000;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4f44e0;
}
.ant-table-wrapper td.ant-table-column-sort {
  background: #fff;
}

.ant-table-body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  /* width of the entire scrollbar */
}

.antTableHeader {
  cursor: move;
  width: 100%;
  display: flex
}

/* .antTableHeader:hover
{
  background-color: pink !important;
} */
.ant-table-column-sorters {
  cursor: pointer !important;
  /* background-color: red !important; */
}

.ant-table-column-sorters>span:not(.ant-table-column-title):hover {
  background-color: green;
  cursor: cell;
}

/* .ant-table-column-sorters span.ant-table-column-sorter.ant-table-column-sorter-full {
  background-color: red !important;
} */
.ant-table-column-sorters span.ant-table-column-sorter.ant-table-column-sorter-full:hover {
  background-color: blueviolet !important;
}

/* .ant-table-column-sorter.ant-table-column-sorter-full
{
  background-color: pink;
  cursor:  pointer !
} */
.ant-table-thead>tr>th {
  cursor: move !important;
  padding: 8px 8px !important;

}

.ant-table-thead>tr>th>div {
  cursor: move !important;
}

.ant-table-column-sorter {
  display: none;
}

.ant-table-wrapper .ant-table-column-title {
  cursor: move;
}

/* .antTableSort
  {
    padding: 0px 0px;
  }*/
.antTableSort:hover {
  background-color: #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  display: inline;
  justify-content: center;
}

.ant-table-thead>tr>th:first-child.ant-table-selection-column {
  cursor: default !important;



}

/* .undragableColumn {
  pointer-events: none !important;
} */

.sortIconDiv {
  display: inline;
  text-align: center;
  position: relative;
  /* top: 2px; */
  cursor: pointer;
  margin-block: -4px;
  margin-inline: -2px -4px;
  padding: -1px 3px;
  /* box-sizing: border-box;
  width: 18px; */
  border-radius: 5px;
  height: 38px;
  /* font-size: 13px;
  /* padding: 2px 2px; */


  /* //height: 40px; */





}

.sortIconDiv:hover {

  background: #e5e5e5;

}


.sortIcon {
  color: rgba(0, 0, 0, 0.29);
  cursor: pointer;
}

.sortIcon.up {
  position: relative;
  /* left: 4px;
  top: -5px; */
  color: rgba(0, 0, 0, 0.29);
  top: 6px
}




.sortIcon.down {
  position: relative;
  /* left: -9px;
  bottom: -3px; */
  color: rgba(0, 0, 0, 0.29);
  bottom: 6px;
}


.sortIcon.active {
  color: #4f44e0;
}


#treemap .apexcharts-datalabel
{
  fill: #000 !important;
  font-size: 12px;
}



.ant-table-body::-webkit-scrollbar-thumb {
  border: 2px solid white;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .3);
  /* roundness of the scroll thumb */
  /* border: 3px solid orange; */
  /* creates padding around scroll thumb */
}

.ant-pagination {
  font-size: 13px;
}

.ant-select-single .ant-select-selector {
  font-size: 13px;
}

.renderID {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-picker {
  border: 1px solid rgb(189,189,189);
}
.ant-picker-input>input::-webkit-input-placeholder {
  color: rgb(150,150,150) !important;
}
.ant-picker-input>input::placeholder {
  color: rgb(150,150,150) !important;
}

.copyIcon {

  cursor: pointer;
  margin: 0 15px;
}

.rowClass {
  cursor: pointer;
}

.specificBattery-kpi {
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 0.9rem;
  /* cursor: pointer; */
  /* box-shadow: rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px; */
}

.specificBattery-kpi-disabled {
  background-color: white;
  border-radius: 20px;
  border: 1px solid #ececec;
  padding: 0.9rem;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px;
}
/* .apexcharts-xaxis-label:nth-last-child(2) {
  transform: translateX(-40px)
} */
/* .specificBattery-kpi:hover {
  border: 1px solid #4f44e0;
} */

.ant-picker-presets>ul>li:active{
  background-color: #473dca;
}
.specificBattery-kpiContainer {
  display: grid;
  grid-gap: 0.9rem;
  gap: 0.9rem;
  grid-template-columns: repeat(6, 140px);
  justify-content: flex-end;
  padding: 0 0.5rem 0.5rem;
}

.mapSlider_open {
  transition: width 0.2s ease-in;

}

.button_hover {
  /* background-color:white; */
  height: 23.5px;
  color: #fff;
  line-height: 25px;
  padding: 5px;
  border-radius: 4px;
  margin-left: 3px;
  /* border: 1px solid #e8e8e8; */
  cursor: pointer;
  font-size: 12.5px;
}

.map_cancel_button {
  /* background-color:white; */
  height: 23.5px;
  color: #4f44e0;
  line-height: 25px;
  padding: 5px;
  border-radius: 4px;
  margin-left: 3px;
  border: 1px solid #4f44e0;
  cursor: pointer;
  font-size: 12.5px;
}

.button_hover:hover {
  text-decoration: underline;
  /* background-color: red; */
}

.mapSlider_close {
  width: 0;
  transition: width 0.3s ease-in;

}

.specificBattery-kpiContainer::-webkit-scrollbar {
  width: 2px !important;
  height: 8px;
  /* background-color:#fff; */
}

.specificBattery-kpiContainer::-webkit-scrollbar-thumb {
  /* background:rgb(250, 250, 250); */
  border-radius: 5px;
}

.profile_Icon {
  cursor: pointer;
  border-radius: 4px;
}

.profile_Icon:hover {
  background: #473dca;
}

.infowindowAlerts_open {
  max-height: 100px;
  /* transition: max-height 1s ease-in; */
}

.infowindowAlerts_closed {
  height: 0px;
  max-height: 0px;
  transition: max-height 1s ease-out;
}

/* .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root
{
  z-index: 10001 !important;
} */

.filterDateRange {
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .filterDateRange {
    display: inline;
  }
}

.responsive_Images {
  width: 80%;
  height: auto;
}

.view {

  -webkit-animation: target-fade 3s;

          animation: target-fade 3s;
}



@-webkit-keyframes target-fade {
  from {
    background-color: #c3c5ff;
  }

  to {
    background-color: transparent;
  }
}



@keyframes target-fade {
  from {
    background-color: #c3c5ff;
  }

  to {
    background-color: transparent;
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid white;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .3);
}

/* ul.MuiList-root.MuiList-padding.MuiList-root
{
} */
/* ul.MuiList-root ::-webkit-scrollbar-thumb {
  border: 2px solid white;
    border-radius: 4px;
    background-color: pink !important;
} */
/* ul.MuiList-root.MuiList-padding.css-zv5wdl-MuiList-root ::-webkit-scrollbar-thumb {
  border: 2px solid rgb(2, 2, 246);
    border-radius: 4px;
    background-color: rgba(255, 0, 0);
} */

/* .multiple_Auto.MuiAutocomplete-root .MuiAutocomplete-inputRoot
::-webkit-scrollbar-thumb {
  border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
} */
/* ul.MuiAutocomplete-listbox
{
  max-height: 400px;
  background-color: #e8e8e8;
  overflow-y: scroll;
}
ul.MuiAutocomplete-listbox
::-webkit-scrollbar-track {
  border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
} */

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper
::-webkit-scrollbar-thumb {
  border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
} */


/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}
::-webkit-scrollbar-thumb {
  background: #775DD0;
}

::-webkit-scrollbar-thumb:hover {
  background: #c4c1c1;
} */

.notiDropDownDiv {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.notiDropDown {
  display: flex;
  flex-direction: column;
  width: 20rem;
  /* z-index: 100; */
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
}
.notiDropDownTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notiDropDownTop p {
  font-size: 15px;
  font-weight: 500;
}
.notiDropDownTop button {
  justify-content: center;
  align-items: center;
}
.notiItemDiv {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;

  /* height: 2.5rem; */
  /* width: 100%; */
  padding: 1rem;
  margin: 1rem 0 1.5rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

.notiContent {
  padding: 0.5rem;
  padding-top: 0;
}

.notiContentHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.notiContentHeading p {
  margin: 0;
  /* color: rgba(108, 117, 125, 1); */
  font-weight: 500;
}

.notiContentBody p {
  margin-top: 1rem;
  color: rgba(108, 117, 125, 1);
  text-transform: capitalize;
}

.notiDropDownMiddle {
  overflow-y: scroll;
  height: 20rem;
}
.notiDropDownMiddle li {
  padding: 0px !important;
}
.itemDiv p {
  margin-left: 0.8rem;
}
.notiDropDownBottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.notiDropDownBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.notiCloseIcon {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #f7f4f4;
  border-radius: 50%;
}


.notiItemContainer {
  width: 30rem;
  /* position: relative; */
  /* top: -49rem; */
  margin-left: -12rem ;
  /* background: grey; */
  max-height: 43rem;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 1rem;
  /* box-shadow: 0 2px 8px 2px rgba(178 ,178,178,0.45); */
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);

}


.nxt{
  -webkit-transform: translateY(-1.5rem);
          transform: translateY(-1.5rem);
  transition: 0.5s all ;
}
.non_deleted{
  -webkit-transform: translateY(-1.5rem);
          transform: translateY(-1.5rem);
  transition: 1s all ;
}

.non_deleted{

  -webkit-transform: translateY(-9.5rem);

          transform: translateY(-9.5rem);
  transition: 0.3s all ;
}


/* .notiItemContainer::-webkit-scrollbar {
  display: none;
} */
.badgeIcon {
  position: absolute;

  z-index: 100;
  bottom: 4.8rem;
  right: 4.7rem;

}

.badgeIcon2{
  position: relative;

  z-index: 100;
 right: 2rem;
}

.deleted {
  -webkit-transform: translateX(-2.5rem);
          transform: translateX(-2.5rem);
  opacity: 0;
  transition: 0.25s all ease-in-out;
}


.notificationButtonDiv{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
}

.notificationButtonDiv .btn{
  /* margin-right: 2rem; */
  border-radius: 100%;
  height: 3rem;
}

.ant-popover-inner{
  box-shadow: none!important;
  padding: 0!important;
  width: 18rem;
  
}

.ant-popover{
  padding: 0!important;
} 
* {
    font-family: 'Helvetica';
}

.tooltip_container {
    padding: 15px;
}

.time_container {
    font-size: 12px;
    font-weight: 400;
    color: #22105C;
}

.flex_center
{
    display: flex;
    align-items: center;
}

.dot_purple {
    height: 13px;
    width: 13px;
    background-color: #4f44e0;
    border-radius: 50%;
    display: inline-block;
}
.dot_red {
    height: 13px;
    width: 13px;
    background-color: #FF7557;
    border-radius: 50%;
    display: inline-block;
}
.dot_yellow {
    height: 13px;
    width: 13px;
    background-color: #F8BC3B;
    border-radius: 50%;
    display: inline-block;
}
.dot_green {
    height: 13px;
    width: 13px;
    background-color: #229087;
    border-radius: 50%;
    display: inline-block;
}
.dot_minCell {
    height: 13px;
    width: 13px;
    background-color: #DD731C;
    border-radius: 50%;
    display: inline-block;
}.dot_cellDiff {
    height: 13px;
    width: 13px;
    background-color: #0876CC;
    border-radius: 50%;
    display: inline-block;
}.dot_capacity {
    height: 13px;
    width: 13px;
    background-color: #6C3483;
    border-radius: 50%;
    display: inline-block;
}

.dot-bmsError-0 {
    height: 13px;
    width: 13px;
    background-color: #D64174;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-1 {
    height: 13px;
    width: 13px;
    background-color: #db5986;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-2 {
    height: 13px;
    width: 13px;
    background-color: #df6a92;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-3 {
    height: 13px;
    width: 13px;
    background-color: #e68dac;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-4 {
    height: 13px;
    width: 13px;
    background-color: #ea9fb9;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-5 {
    height: 13px;
    width: 13px;
    background-color: #eeb0c5;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-6 {
    height: 13px;
    width: 13px;
    background-color: #f2c2d2;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-7 {
    height: 13px;
    width: 13px;
    background-color: #f4cad8;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-8 {
    height: 13px;
    width: 13px;
    background-color: #f9e5ec;
    border-radius: 50%;
    display: inline-block;
}
.dot-bmsError-9 {
    height: 13px;
    width: 13px;
    background-color: #fae9ef;
    border-radius: 50%;
    display: inline-block;
}

.text_container
{
    /* margin-left: 10px; */
    font-size: 12px;
    font-weight: 400;
    color: #22105C;
}

.battery_status_container
{
    font-size: 12px;
    font-weight: 400;
    color: #22105C;
}

.horizontal_rule
{
    border-top: 1px solid #ececec;
}
.horizontal_rule_white
{
    border-top: 0px none #fff;
}

.displaying_None
{
    display: none;
}
.displaying_Br_None
{
    display: none;
}
.displaying_Br
{
    display: inline-block;
}
.margin_Top
{
    padding: 10px;
}
.annotClass{
    height: 5rem;
}

/* #SvgjsRect1047{
    height: 5rem;
    width: 7rem;
    position: relative;
} */

#SvgjsRect1054{
    border-radius: 50%;
    position: absolute;
    top: 1px;
}

.popUpContainer{
    
    position: absolute;
    background-color: white;
    /* text-align: center; */
    color: black;
    z-index: 100;
    border-radius: 1rem;
    box-shadow: 0 2px 8px 2px rgba(178 ,178,178,0.45);
    /* display: flex;
    align-items: center; */
           
}

.popupLine{
   
    height: 80%;
    /* width: 1px; */
    border: 3px solid ;
    margin: 1rem;
    border-radius: 1rem;
    align-items: flex-start;

}

.popupContent{
    width: 100%;
    height: 100%;
    /* align-items: center; */
}



.closeIcon{
    position: relative;
    top: 0rem;
    right: 0rem;
    cursor: pointer;
}

.yAxisAnnot{
    fill: red!important;
    height: 2rem!important;
    width: 2rem!important;
}

.svgClass{
   cursor: pointer;
}

/* .apexcharts-text .apexcharts-xaxis-annotation-label .yAxisAnnot {
    cursor: pointer!important;
} */


.date_picker_div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: grey; */

}

.date_picker {
    width: 1000px;
}


.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {

    display: none;

}


.rIcon {
    position: relative;
    left: -23px;
    z-index: 10;
    height: 1.3rem;
}
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"); */


.tableComp{
    /* display: flex; */
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 80%;
    font-family: "Roboto";
    /* background-color: #212138; */
    margin: 10%;
}
.dropDown{
    display: flex;
    flex-direction: column;
    width: 250px;
    /* z-index: 100; */
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
  

}
.dropDownTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.dropDownTop p{
    font-size: 15px;
    font-weight: 500;
}
.dropDownTop button{
    justify-content: center;
    align-items: center;
}
.itemDiv{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    height: 1.5rem;
    padding: 0.5rem 0rem;
}
.dropDownMiddle
{
    margin-top: 0.5rem;

}
.dropDownMiddle li {
    padding: 0px!important;
}
.itemDiv p{
    margin-left: 0.8rem;
}
.dropDownBottom{

    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    
    }
    
    .dropDownBottom button{
        margin: 0px 0px ;
        padding: 4px 10px;
        font-size: 12px;
    }
    

.disabled-row {
    background-color: #dcdcdc;
    /* background-color: red; */
    pointer-events: none;
  }

.copyIcon{
   
    cursor: pointer;
    margin:  0 15px;
}


.dropDownBox{
    width: 500px;
    display: flex;
    justify-content: flex-end;
}
.dropDowndDiv{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}





.checkbox-color .ant-checkbox-disabled .ant-checkbox-inner{
  background-color: #d9d9d9;
  border-color:#d9d9d9;
  display: none;
}
.antnoti{
    background-color: rgb(28,28,28)!important;
    color: white;
    z-index: 20000 !important;
  }

  .ant-notification-notice-close{
    color: white!important;
  }

  .ant-notification-notice-message{
    color: #fff!important;
  }
.sideBox {
    height: 341px;
    width: 1.6rem;
    /* background-color: #fff; */
    background-color: white;
    position: absolute;
    top: 5.2rem;

}
.left{
    left: 6.5rem;
}

.right{
    right: 0.3rem;
}

.topBox
{
    height: 1rem;
    width: calc(100% - 9.8rem);
    background-color: white;
    position: absolute;
    left: 8rem;
    right: 0rem;
}

.top{
    top: 5.2rem;
    /* z-index: -1; */
}

.bottom{
    top:383px;
}

.topBox_packMetrics
{
    height: 3.5rem;
    width: 11.5rem;
    background-color: #fff;
    position: absolute;
    left: 1.1rem;
    right: 0rem;
    top: 18.2rem;
}
.mainDiv{
    width: 100%;
    height: 100vh;
}
.notMainDiv
{
    height: 100%
}


.mapDiv{
    width: 100%;
    height: 100%;
   
}

.sideDivMap{
    /* width: 25%; */
    /* height: 100%; */
    display: flex;
    height: 5rem;
}

.loaderDiv{
    /* width: 100%; */
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 50%;
    top:40%;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.dropDownMap{
    display: flex;
    flex-direction: column;
    width: 7rem;
    /* z-index: 100; */
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
  

}

.dropDownBoxMap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.dropDowndDivMap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.itemDivMap{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    height: 1.5rem;
}
.itemDivMap p{
    margin-left: 0.8rem;
    text-transform: capitalize;
}
.locationDropdownButton{
    width: 8.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 5px;
    display: inline-block;
}
.login {

    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 5rem;
    height: 60vh;

    align-items: center;
    box-sizing: border-box;
}

.loginFields {
    border-radius: 1rem !important;
}

.box {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    padding: 5rem 2rem;
    /* background-color: #dddada; */
    border: 1px solid #bfbfbf;
    border-radius: 1rem;
    width: 28rem;
    justify-content: center;
    align-items: center;
    /* height: 10rem; */

}

.box img {
    height: 3rem;
    width: 3rem;
    position: relative;
    top: -2rem;
    /* right: -6rem; */

}

.button {
    width: 100% !important;
    border-radius: 0.5rem !important;
    margin-bottom: 1rem !important;
}

.button.resetPasswordBtn {
    margin-top: 2rem !important;


}

.input {
    background-color: white;
    border-radius: 0.5rem !important;
    width: 100%;
    border-color: #ef5350 !important;
}

.forgot {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.forgot p {

    margin: 0;
    margin-bottom: 2rem;
    color: #6e6464;
    cursor: pointer;
}

.forgot .error {
    margin: 0;
    margin-bottom: 2rem;
    cursor: pointer;
    color: #ef5350;

}

.forgot p {
    cursor: pointer;
}

.messageBox {

    margin: 1rem;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    background-color: white;
    width: 100%;
    justify-content: center;
    display: inline;
                    text-align:center;
                    padding: 10px
}

.invalidLink {
    margin: 1rem 0;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid white;

}

.passChangedSuccess {
    margin: 1rem 0;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid white;

}

.messageBox.error {
    border: 1px solid #ef5350;
}


.messageBox.info {
    border: 1px solid blue;
}


.messageBox.success {
    border: 1px solid green;
}



.successBox {
    border: 1px solid green;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: white;
}

.successBox p {
    color: green;
}

.resetHeading {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 0;
}
