.notiDropDownDiv {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
.notiDropDown {
  display: flex;
  flex-direction: column;
  width: 20rem;
  /* z-index: 100; */
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
}
.notiDropDownTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notiDropDownTop p {
  font-size: 15px;
  font-weight: 500;
}
.notiDropDownTop button {
  justify-content: center;
  align-items: center;
}
.notiItemDiv {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;

  /* height: 2.5rem; */
  /* width: 100%; */
  padding: 1rem;
  margin: 1rem 0 1.5rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

.notiContent {
  padding: 0.5rem;
  padding-top: 0;
}

.notiContentHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.notiContentHeading p {
  margin: 0;
  /* color: rgba(108, 117, 125, 1); */
  font-weight: 500;
}

.notiContentBody p {
  margin-top: 1rem;
  color: rgba(108, 117, 125, 1);
  text-transform: capitalize;
}

.notiDropDownMiddle {
  overflow-y: scroll;
  height: 20rem;
}
.notiDropDownMiddle li {
  padding: 0px !important;
}
.itemDiv p {
  margin-left: 0.8rem;
}
.notiDropDownBottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.notiDropDownBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.notiCloseIcon {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #f7f4f4;
  border-radius: 50%;
}


.notiItemContainer {
  width: 30rem;
  /* position: relative; */
  /* top: -49rem; */
  margin-left: -12rem ;
  /* background: grey; */
  max-height: 43rem;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 1rem;
  /* box-shadow: 0 2px 8px 2px rgba(178 ,178,178,0.45); */
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);

}


.nxt{
  transform: translateY(-1.5rem);
  transition: 0.5s all ;
}
.non_deleted{
  transform: translateY(-1.5rem);
  transition: 1s all ;
}

.non_deleted{

  transform: translateY(-9.5rem);
  transition: 0.3s all ;
}


/* .notiItemContainer::-webkit-scrollbar {
  display: none;
} */
.badgeIcon {
  position: absolute;

  z-index: 100;
  bottom: 4.8rem;
  right: 4.7rem;

}

.badgeIcon2{
  position: relative;

  z-index: 100;
 right: 2rem;
}

.deleted {
  transform: translateX(-2.5rem);
  opacity: 0;
  transition: 0.25s all ease-in-out;
}


.notificationButtonDiv{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
}

.notificationButtonDiv .btn{
  /* margin-right: 2rem; */
  border-radius: 100%;
  height: 3rem;
}

.ant-popover-inner{
  box-shadow: none!important;
  padding: 0!important;
  width: 18rem;
  
}

.ant-popover{
  padding: 0!important;
} 